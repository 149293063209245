#projects {
  width: 100vw;

  .container {
    .section-header {
      text-align: center;
    }

    .project-wrapper {
      width: 70vw;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 3rem;
        line-height: 20px;
      }

      .grid {
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        @media (max-width: 414px) {
          grid-template-columns: 1fr;
        }
        //   @include media(">=phone", "<lgphone") {
        //     grid-template-columns: 1fr;
        //   }
        //   @include media(">=lgphone", "<tablet") {
        //     grid-template-columns: 1fr;
        //   }
        //   @include media(">=tablet", "<desktop") {
        //     grid-template-columns: 1fr 1fr;
        //   }
      }
    }
  }
}
