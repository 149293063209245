.container {
  .header {
    text-align: center;

    a {
      color: black;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    .icon {
      color: #dadee8;
    }
  }
  
  a {
    text-decoration: none;
    color: white;
  }

}
