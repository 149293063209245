.App {
  /* margin: 0 200px; */
  /* @media screen and (max-width: 1080px) {
    margin: 0 400px;
  } */
}

.container {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-header {
  /* text-align: center; */
  font-size: 3rem;
  font-weight: bolder;
  padding: 20px 0 20px 0;
}
