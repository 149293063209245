.card {
    width: 100%;
    height: 250px;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    .content {
      text-align: center;
      padding: 10px;
      opacity: 0;
      transition: all 500ms ease-in-out;
      transform: translate(0, 20px);
      cursor: pointer;
      h1 {
        font-size: 20px;
        color: black;
      }
      p {
        font-size: 18px;
        color: black;
        margin-bottom: 20px;
      }
      .basic {
        margin: 10px 0 10px 0;
      }
      .link {
        color: black;
        text-decoration: none;
      }
      &:hover {
        opacity: 1;
        transform: translate(0, 0px);
      }
    }
  }
  